import Tilt from 'react-tilt'
import diesel from '../../assets/community/diesel.jpg'
import room from '../../assets/community/room.jpg'
import "./style.css"

export default function RoadmapSection() {
  return (
    <div className="roadmapContainer sectionSpacing">
      <h3 className="header">ROADMAP</h3>
      <div className="roadmap">
        <div className="timeline" />

        <div className="roadmapSection">
          <div className="roadmapPath">
            <div className="roadmapDot" />
            <img src={diesel} alt="NYC" />
          </div>

          <Tilt options={{ perspective: 950, scale: 1.025 }} className="roadmapContent">
            <div className="roadmapHeadline">
              <h4>MINT</h4>
              <p>July 2022</p>
            </div>
            <p>Dedfam will go through three phases of minting - dutch auction, whitelist, and public sale.</p>
          </Tilt>
        </div>

        <div className="roadmapSection">
          <div className="roadmapPath">
            <div className="roadmapDot" />
            <img src={room} alt="NYC" />
          </div>

          <Tilt options={{ perspective: 950, scale: 1.025 }} className="roadmapContent">
            <div className="roadmapHeadline">
              <h4>REVEAL</h4>
              <p>July 2022</p>
            </div>
            <p>A public reveal for the Dedfam collection will initiate once all NFTs are minted,</p>
          </Tilt>
        </div>

        <div className="roadmapSection">
          <div className="roadmapPath">
            <div className="roadmapDot" />
            <img src={diesel} alt="NYC" />
          </div>

          <Tilt options={{ perspective: 950, scale: 1.025 }} className="roadmapContent">
            <div className="roadmapHeadline">
              <h4>NOMINATE CREATIVE DIRECTORS</h4>
              <p>October 2022</p>
            </div>
            <p>Dedfam will seek out three of the best up and coming street style designers and creative directors to compete in a Project Runway style competition show.</p>
          </Tilt>
        </div>

        <div className="roadmapSection">
          <div className="roadmapPath">
            <div className="roadmapDot" />
            <img src={room} alt="NYC" />
          </div>

          <Tilt options={{ perspective: 950, scale: 1.025 }} className="roadmapContent">
            <div className="roadmapHeadline">
              <h4>SHOW BEGINS</h4>
              <p>December 2022</p>
            </div>
            <p>Dedfam's Project Runway style competition show will start to air on a weekly basis.</p>
          </Tilt>
        </div>

        <div className="roadmapSection">
          <div className="roadmapPath">
            <div className="roadmapDot" />
            <img src={room} alt="NYC" />
          </div>

          <Tilt options={{ perspective: 950, scale: 1.025 }} className="roadmapContent">
            <div className="roadmapHeadline">
              <h4>VOTE CREATIVE DIRECTOR</h4>
              <p>January 2023</p>
            </div>
            <p>A week after Dedfam's competition show's last episode, a vote for Dedfam NFT holders will be held to decide the new brand's Creative Director.</p>
          </Tilt>
        </div>

        <div className="roadmapSection">
          <div className="roadmapPath">
            <div className="roadmapDot" />
            <img src={diesel} alt="NYC" />
          </div>

          <Tilt options={{ perspective: 950, scale: 1.025 }} className="roadmapContent">
            <div className="roadmapHeadline">
              <h4>VOTE BRAND NAME</h4>
              <p>March 2023</p>
            </div>
            <p>A vote for Dedfam NFT holders will be held to decide the new brand's name.</p>
          </Tilt>
        </div>

        <div className="roadmapSection">
          <div className="roadmapPath">
            <div className="roadmapDot" />
            <img src={room} alt="NYC" />
          </div>

          <Tilt options={{ perspective: 950, scale: 1.025 }} className="roadmapContent">
            <div className="roadmapHeadline">
              <h4>VOTE BRAND COLLECTION SIGNATURE PIECES</h4>
              <p>April 2023</p>
            </div>
            <p>A vote for Dedfam NFT holders will be held to decide key pieces in the brand's line.</p>
          </Tilt>
        </div>

        <div className="roadmapSection">
          <div className="roadmapPath">
            <div className="roadmapDot" />
            <img src={room} alt="NYC" />
          </div>

          <Tilt options={{ perspective: 950, scale: 1.025 }} className="roadmapContent">
            <div className="roadmapHeadline">
              <h4>BRAND DROPS</h4>
              <p>June 2023</p>
            </div>
            <p>Dedfam's community created brand launches IRL and in the Metaverse 💀💀💀</p>
          </Tilt>
        </div>
      </div>
    </div>
  )
}