import { useState } from "react"
import ScrollContainer from 'react-indiana-drag-scroll'
import ruben from '../../assets/community/ruben.jpg'
import diesel from '../../assets/community/diesel.jpg'
import pleasurecraft from '../../assets/community/pleasurecraft.jpg'
import room from '../../assets/community/room.jpg'
import rubenNYC from '../../assets/community/rubenNYC.jpg'
import theride from '../../assets/community/theride.jpg'
import zpeople from '../../assets/community/zpeople.jpg'
import zman from '../../assets/community/zman.jpg'
import "./style.css"

const community = [
  {
    img: zpeople,
    title: 'Lovers.',
    creator: 'Ziyu',
    creatorPic: zman,
    medium: 'Cinema4D',
    social: 'https://www.instagram.com/ziyu.he/',
  },
  {
    img: diesel,
    title: 'Retail Therapist 🛍',
    creator: 'Ruben',
    creatorPic: ruben,
    medium: 'Photography',
    social: 'https://www.instagram.com/deityondrugs/',
  },
  // {
  //   img: room,
  //   title: '💫💫',
  //   creator: 'Ruben',
  //   creatorPic: ruben,
  //   medium: 'Photography',
  //   social: 'https://www.instagram.com/deityondrugs/',
  // },
  {
    img: theride,
    title: 'The Ride',
    creator: 'PleasureCraft',
    creatorPic: pleasurecraft,
    medium: 'Cinema4D',
    social: 'https://www.instagram.com/pleasurecraft.fun/',
  },
  {
    img: rubenNYC,
    title: 'A Night in NYC',
    creator: 'Ruben',
    creatorPic: ruben,
    medium: 'Photography',
    social: 'https://www.instagram.com/deityondrugs/',
  },
]

const CommunityItem = ({ img, title, creator, creatorPic, social, description }) => (
  <div className="communityItem">
    <img className="featureImage" src={img} />

    <h3>{title}</h3>

    <a href={social} className="creator">
      <img src={creatorPic} />
      <h4>{creator}</h4>
    </a>

    {description && <p>{description}</p>}
  </div>
)

export default function ExploreSection() {
  const [active, setActive] = useState(0)

  return (
    <div className="sectionSpacingNoWidth">
      <h3 className="header noPaddingHeader">COMMUNITY WORK</h3>
      <div className="communitySlideshow">
        {community.map(c => <CommunityItem {...c} />)}
      </div>
    </div>
  )
}